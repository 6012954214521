// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  createDigitalApiBaseUrl: 'https://api2.createdigitalsolutions.com',
  examRevisionApiBaseUrl: 'https://legacybe-staging.examrevision.ie',
  examRevisionApiBaseUrlV2: 'https://be-staging.examrevision.ie',
  examRevisionServerlessUrl: 'https://jyqhm1suo4.execute-api.eu-west-2.amazonaws.com',

  // examRevisionApiBaseUrl: 'http://localhost:8080',
  // examRevisionApiBaseUrlV2: 'http://localhost:3000',
  // examRevisionServerlessUrl: 'https://jyqhm1suo4.execute-api.eu-west-2.amazonaws.com',
};

// export const environment = {
//   production: false,
//   createDigitalApiBaseUrl: 'https://api2.createdigitalsolutions.com',
//   examRevisionApiBaseUrl: 'http://localhost:8080',
//   examRevisionApiBaseUrlV2: 'http://localhost:3000',
//   examRevisionServerlessUrl: 'https://jyqhm1suo4.execute-api.eu-west-2.amazonaws.com',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
